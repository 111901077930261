<template>
  <div>
      <!-- Header section -->
      <el-row :gutter="0">
          <el-col :span="20" :offset="2">
              <header class="page-header">
                  <el-row :gutter="20" align="middle">
                    <el-col :span="12">
                        <h2>Нийлүүлэгчийн жагсаалт</h2>
                    </el-col>
                  </el-row>
              </header>
              <!-- Core content -->
              <div class="panel tabled">
                  <el-tabs v-model="activeTabStatus">
                      <el-tab-pane label="Бүгд" name="all">
                          <div class="panel-content">
                              <div class="pl20 pr20 table-filter mb10">
                                  <el-row :gutter="10" align="middle" type="flex">
                                      <el-col :span="20">
                                          <el-input placeholder="Хайх" class="input-with-select" v-model="search" @input="onSearch">
                                              <el-button icon="el-icon-search" slot="prepend"></el-button>
                                          </el-input>
                                      </el-col>
                                      <el-col :span="4" :offset="4" class="text-right">
                                          <el-dropdown @command="handleSort">
                                              <el-button type="default">{{sortName}} <i class="el-icon-sort"></i></el-button>
                                              <el-dropdown-menu slot="dropdown">
                                                  <el-dropdown-item :command='filter.value' v-for="(filter, indexFilter) in filterData" :key="indexFilter">{{filter.label}}</el-dropdown-item>
                                              </el-dropdown-menu>
                                          </el-dropdown>
                                      </el-col>
                                  </el-row>
                              </div>
                              <supplier-table :isLoading="isLoading" :pageFrom="pageFrom" :suppliers="suppliers" :pageSize="pageSize" :search="search" :suggestedSupplier="suggestedSupplier" :page="currentPage" :size="pageSize" :field="field" :sort="sort"></supplier-table>
                              <el-pagination
                                  @current-change = "curentPageHandler"
                                  @size-change = "sizeChangeHandler"
                                  class="text-right mt10"
                                  background
                                  :current-page.sync="currentPage"
                                  :page-size="pageSize"
                                  :page-sizes="[5, 20, 50, 100]"
                                  layout="total, sizes, prev, pager, next"
                                  :total="totalCount">
                                </el-pagination>
                          </div>
                      </el-tab-pane>
                  </el-tabs>
              </div>
          </el-col>
      </el-row>
  </div>
</template>
<script>
import supplierTable from './components/supplierTable'
import services from '../../../helpers/services'
export default {
  components: {
    supplierTable
  },
  data () {
    return {
      sortName: 'Эрэмбэлэх',
      sort: '',
      field: '',
      currentPage: 1,
      pageSize: 20,
      page: 1,
      search: '',
      suppliers: [],
      activeTabStatus: 'all',
      pageFrom: 0,
      totalCount: 0,
      isLoading: false,
      visibleSupplierDialog: false,
      supplierLoading: false,
      suggestedSupplier: [],
      filterData: [
        {
          label: 'Бүгд',
          value: 'all'
        }, {
          label: 'Нэр А - Я',
          value: 'firstChar'
        }, {
          label: 'Нэр Я - А',
          value: 'lastChar'
        }, {
          label: 'Шинэ эхэнд',
          value: 'newest'
        }, {
          label: 'Хуучин эхэнд',
          value: 'oldest'
        }
      ]
    }
  },
  mounted () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      const incomingSearch = this.$route.query.search
      const incomingField = this.$route.query.field
      const incomingSort = this.$route.query.sort
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      this.search = incomingSearch
      this.field = incomingField
      this.sort = incomingSort
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.getSuppliers(from, size, this.search, this.field, this.sort)
  },
  methods: {
    onSearch () {
      this.$router.push({ name: 'supplierList', query: { page: this.currentPage, size: this.pageSize, search: this.search, field: this.field, sort: this.sort } }).catch(() => {})
      this.getSuppliers((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search, this.field, this.sort)
    },

    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({ name: 'supplierList', query: { page: item, size: this.pageSize, search: this.search, field: this.field, sort: this.sort } }).catch(() => {})
      this.getSuppliers((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search, this.field, this.sort)
    },
    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({ name: 'supplierList', query: { page: item, size: this.pageSize, search: this.search, field: this.field, sort: this.sort } }).catch(() => {})
      this.getSuppliers((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search, this.field, this.sort)
    },

    handleSort (data) {
      const filters = {
        newest: {
          field: 'created_at',
          sort: 'desc',
          sortName: 'Шинэ эхэнд'
        },
        oldest: {
          field: 'created_at',
          sort: 'asc',
          sortName: 'Хуучин эхэнд'
        },
        firstChar: {
          field: 'name_mon',
          sort: 'asc',
          sortName: 'Нэр А - Я'
        },
        lastChar: {
          field: 'name_mon',
          sort: 'desc',
          sortName: 'Нэр Я - А'
        },
        all: {
          field: '',
          sort: '',
          sortName: 'Эрэмбэлэх'
        }
      }
      const currentFilter = JSON.parse(JSON.stringify(filters[data]))
      this.field = currentFilter.field
      this.sort = currentFilter.sort
      this.sortName = currentFilter.sortName
      this.$router.push({ name: 'supplierList', query: { page: this.currentPage, size: this.pageSize, search: this.search, field: this.field, sort: this.sort } }).catch(() => {})
      this.getSuppliers(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.field, this.sort)
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'top-right'
      })
    },

    getSuppliers (from, size, search, field, sort) {
      const query = '?search_text=' + search + '&from=' + from + '&size=' + size + '&field=' + field + '&sort=' + sort
      this.isLoading = true
      services.getSuppliers(query).then(response => {
        this.totalCount = response.total
        this.suppliers = response.data
        this.isLoading = false
      })
    }
  }
}
</script>
