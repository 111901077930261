<template>
  <div>
    <el-table
      :data='suggestedSupplier.length > 0 ? suggestedSupplier : suppliers'
      v-loading="isLoading"
      style="width: 100%"
      @cell-click="clickCell">
      <el-table-column label="Нэр">
        <template slot-scope="scope">
            <span>{{ scope.row.supplier_monName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Регистер">
        <template slot-scope="scope">
          <span>{{scope.row.supplier_regno}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Утас">
        <template slot-scope="scope">
          <span>{{scope.row.phone}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="Төлөв">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isActive" type="success">Идэвхтэй</el-tag>
          <el-tag v-else type="warning">Идэвхгүй</el-tag>
        </template>
      </el-table-column> -->
      <el-table-column label="Хаяг">
        <template slot-scope="scope">
          <span>{{scope.row.address}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Байршил">
        <template slot-scope="scope">
          <span>{{scope.row.location}}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  components: {
  },
  props: [
    'search',
    'isLoading',
    'suppliers',
    'totalCount',
    'suggestedSupplier',
    'pageSize',
    'field',
    'sort',
    'page'
  ],
  methods: {
    closeModal () {
      this.visibleSupplierDialog = false
    },

    clickCell (row, column, cell, event) {
      this.$router.push(
        {
          name: 'supplierEdit',
          params: {
            supplier_id: row.supplier_id
          },
          query: { page: this.page, size: this.pageSize, search: this.search, field: this.field, sort: this.sort }
        }
      )
    }
  }
}
</script>
